import React from "react";
import { Content } from "../components/content";
import { H1, Text } from "../components/text";
import { Hero } from "../components/hero";
import { Layout } from "../components/layout";
import { Seo } from "../components/seo";
import HeroImage from "../images/pages/courses/hero.jpg";
import { Grid } from "../components/grid";
import { TripCard } from "../components/trip-card";
import { Loading } from "../components/loading";
import { Spacer } from "../components/spacer";

export const Courses = ({ courses, isLoading }) => {
  return (
    <Layout>
      <Seo lang="en" title="Train with us // ITC Courses" image={HeroImage} />
      <Hero image={HeroImage}>
        <H1>WVS International Training Centre Courses</H1>
      </Hero>
      <Content>
        <Text>
          WVS India operates two international training centers (ITCs), one in
          the Nilgiris, Tamil Nadu (est. 2010) and the other in Bardez, Goa
          (est. 2016). The objective of the ITCs is to provide veterinary skills
          development, inspiration and advice for veterinarians and veterinary
          assistants especially in the field of humane dog population control
          and rabies control.
        </Text>
        <Text>
          The ITC surgery courses provide the participants with skills and
          confidence in good quality surgery and related clinical skills and
          increased understanding in veterinarian’s role in public health
          matters like rabies control and responsible use of antibiotics. It
          also challenges veterinarians to think how they can be actively
          promoting animal welfare in their daily work.
        </Text>
        <Text>
          The courses are meant for veterinarians and last year veterinary
          students, who are actively working in the clinical field and who are
          likely to be able to spay/neuter dogs after the course.
        </Text>
        <Text>
          We provide vocational training in the veterinary assistant and animal
          handler work parallel to the ABC surgery courses for non-veterinary
          people interested in working in ABC projects. We also welcome ABC
          project managers to attend the course to learn and see in practice the
          important elements of good ABC-surgery project.
        </Text>
        <Spacer height={[3]} />
        {isLoading ? (
          <Loading />
        ) : (
          <Grid centreItems columns={3} withBottomMargin>
            {courses.map((project) => (
              <TripCard
                key={project.id}
                location={project.country}
                imageSource={
                  project.images &&
                  project.images.length &&
                  project.images.find((image) => image.featured).url
                }
                title={project.name}
                to={project.path}
              />
            ))}
          </Grid>
        )}
      </Content>
    </Layout>
  );
};
