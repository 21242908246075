import { useState, useEffect } from "react";
import { getTrainingProjects, getVolunteerProjects } from "../services/portal";

export const useProjects = (type) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let func;

    switch (type) {
      case "training":
        func = getTrainingProjects;
        break;
      case "volunteer":
        func = getVolunteerProjects;
        break;
      default:
        break;
    }

    const getProjectsAsync = async () => {
      const response = await func();

      if (response.success) {
        setProjects(
          response.projects.map((project) => {
            const path = `/courses/${project.slug}`;

            return {
              ...project,
              path,
            };
          })
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setProjects([]);
      }
    };

    if (func) {
      getProjectsAsync();
    }
  }, [type]);

  return { projects, isLoading };
};
